import React from "react";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import "twin.macro";

import Layout from "./Layout";

export default function LayoutMDX({ children }) {
  return (
    <Layout>
      {/* https://github.com/tailwindlabs/tailwindcss-typography */}
      <Box
        as="article"
        p={{ base: 4, lg: 8 }}
        // fontSize={{ base: "md", lg: "2xl" }}
        tw="prose lg:prose-xl"
      >
        {children}
      </Box>
    </Layout>
  );
}

LayoutMDX.propTypes = {
  children: PropTypes.node.isRequired,
};
